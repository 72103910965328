import React, { Component } from 'react'
import {Navbar, Nav, NavDropdown, Button} from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import { withOktaAuth } from '@okta/okta-react';



export default withOktaAuth (class NavBar extends Component {
   //static contextType = MainContext;
    //static contextType = userContext;

    constructor(props) {
        super(props)
        this.logout = this.logout.bind(this);
        this.login = this.login.bind(this);
        this.state = {
            userInfo: null,
            userGroups: null,
            isAuthenticated: JSON.parse(localStorage.getItem('isAuthenticated')),
            oktaInformation: null,
            reload: null,
            count:0,
            data:[]
          }
        this.count = 0;
        this.adminGroup = "Admin";
        this.adminReadGroup = "Admin-Read";
        this.userGroup = "Users";
        this.cleaningGroup = "Cleaning-Admin";
        this.lawnLeadersGroup = "Lawn-Leader";
        this.reload = 0;
        this.count+=1; 
    }

    async logout() {
        this.props.oktaAuth.signOut('/');
    }

    async login() {
        this.props.oktaAuth.signInWithRedirect();
    }
 
    async componentDidMount() {
        let userInfo = await this.props.oktaAuth.token.getUserInfo();
        this.setState({ data: userInfo });
        try{
        }
        catch(error){
            console.log(error); 
            alert(error);
        }

  }
    render() {  
    if (!this.props.authState) return <div>Loading...</div>;

    return this.props.authState.isAuthenticated ? 
        <Navbar bg="dark" expand="lg" variant="dark">
            <LinkContainer to="/">
                <Navbar.Brand>Home</Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
        { (this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.adminGroup) || 
            this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.adminReadGroup) ||
            this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.lawnLeadersGroup) )
            !== null &&
            <Nav className="mr-auto">
                {/* <LinkContainer to="/service">
                    <Nav.Link>Service</Nav.Link>
                </LinkContainer> */}
                <NavDropdown 
                    data-cy={"navbar-reports"}
                    title="Reports" 
                    id="collasible-nav-dropdown"
                >
                { (this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.adminGroup) ||
                    this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.lawnLeadersGroup)) &&
                    <LinkContainer 
                        to="/work-data"
                        data-cy={"navbar-reports-work-reports"}
                    >
                        <NavDropdown.Item href="#action/3.1">Work Reports</NavDropdown.Item>
                    </LinkContainer>
                }
                    {/* { this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.adminGroup) &&
                        <NavDropdown.Item href="#action/3.2">Cost Reports</NavDropdown.Item>
                    } */}
                    { this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.cleaningGroup) && 
                    <LinkContainer 
                        to="/clean-data"
                        data-cy={"navbar-reports-cleaning"}
                    >
                        <NavDropdown.Item href="#action/3.1" data-cy="cleaning-report">Cleaning</NavDropdown.Item>
                    </LinkContainer>
                    }
                    { this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.adminGroup) &&
                        <LinkContainer 
                            to="/track-work-data"
                            data-cy={"navbar-reports-work-schedule-tracking"}
                        >
                            <NavDropdown.Item href="#action/3.1">Work Schedule Tracking</NavDropdown.Item>
                        </LinkContainer>
                    }
                    { this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.adminGroup ||
                        this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.lawnLeadersGroup) ) &&
                        <LinkContainer
                            data-cy={"navbar-reports-estimates-tracking"} 
                            to="/track-estimate"
                        >
                            <NavDropdown.Item href="#action/3.1">Estimates Tracking</NavDropdown.Item>
                        </LinkContainer>
                    }
                    { 
                        <LinkContainer
                            data-cy={"navbar-reports-invoices"} 
                            to="/invoices"
                        >
                            <NavDropdown.Item href="#action/3.1">Invoices</NavDropdown.Item>
                        </LinkContainer>
                    }

                </NavDropdown>
                { this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.adminGroup) &&
                <NavDropdown 
                    data-cy={"navbar-customers"}
                    title="Customers" 
                    id="collasible-nav-dropdown">
                    <LinkContainer 
                        to="/customers"
                        data-cy={"navbar-customers-customer-information"}
                    >
                    { this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.adminGroup) &&
                        <NavDropdown.Item href="#action/3.1">Customer Information</NavDropdown.Item>
                    }
                    </LinkContainer>
                    <LinkContainer 
                        to="/addcustomer"
                        data-cy={"navbar-customers-add-customer"}
                    >
                    { this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.adminGroup) &&
                        <NavDropdown.Item href="#action/3.1">Add Customer</NavDropdown.Item>
                    }
                    </LinkContainer>
                </NavDropdown>
                }
                { (this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.adminGroup) ||
                      this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.cleaningGroup)) && 
                <NavDropdown 
                    data-cy={"navbar-cleaning"}
                    title="Cleaning" 
                    id="collasible-nav-dropdown">
                    <LinkContainer 
                        data-cy={"navbar-cleaning-crews"}
                        to="/cleaning-crew"
                    >
                    { (this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.adminGroup) ||
                      this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.cleaningGroup)) && 
                        <NavDropdown.Item href="#action/3.1">Crews</NavDropdown.Item>
                    }
                    </LinkContainer>
                    <LinkContainer 
                        data-cy={"navbar-cleaning-communities"}
                        to="/communities"
                    >
                    { (this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.adminGroup) ||
                        this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.cleaningGroup)) && 
                        <NavDropdown.Item href="#action/3.1">Community</NavDropdown.Item>
                    }
                    </LinkContainer>
                    <LinkContainer 
                        data-cy={"navbar-cleaning-builders"}
                        to="/builders"
                    >
                    { (this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.adminGroup) ||
                        this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.cleaningGroup)) && 
                        <NavDropdown.Item href="#action/3.1">Builders</NavDropdown.Item>
                    }
                    </LinkContainer>
                </NavDropdown>
                }
                { (this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.adminGroup)) && 
                <NavDropdown 
                    data-cy={"navbar-locations"}
                    title="Locations" 
                    id="collasible-nav-dropdown">
                    <LinkContainer 
                        data-cy={"navbar-tracking"}
                        to="/location-tracking"
                    >
                    { (this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.adminGroup) ||
                      this.props.oktaAuth.authStateManager["_authState"].accessToken.claims["Add-Groups"].includes(this.cleaningGroup)) && 
                        <NavDropdown.Item href="#action/3.1">Tracking</NavDropdown.Item>
                    }
                    </LinkContainer>
                </NavDropdown>
                }
            </Nav>
        }
            <Nav className="justify-content-end" style={{ width: "100%" }}>
                <NavDropdown title={ `Signed in as: ${this.state.data.name}`}>
                    <LinkContainer to="#">
                    <NavDropdown.Item href="#action/3.1">Settings</NavDropdown.Item>
                    </LinkContainer>
                    <Button variant="outline-danger" onClick={this.logout}>Logout</Button>
                </NavDropdown>
            </Nav>
        
        </Navbar.Collapse>
        </Navbar>: 
                <Navbar bg="dark" expand="lg" variant="dark">
                <LinkContainer to="/">
                    <Navbar.Brand>Home</Navbar.Brand>
                </LinkContainer>
                <Nav 
                    className="justify-content-end" 
                    style={{ width: "100%" }}
                >
                    <Button 
                        data-cy="nav-login-button"
                        variant="success" 
                        onClick={this.login}
                    >
                    Login
                    </Button>{' '}
                </Nav>
            </Navbar>
    
}});



