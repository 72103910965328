import React from "react";

export const EditableCellTime = ({
    value: initialValue,
    row: { index, isSelected },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
    editable,
    toggleRowSelected
  }) => {
    // We need to keep and update the state of the cell normally
  
    if(id == 'active'){
      initialValue = initialValue || false;
    } else{
      initialValue = initialValue || '';
    }
  
    const [value, setValue] = React.useState(initialValue);
  
    const onChange = (e) => {
      setValue(e.target.value);
  
      if(!isSelected){
        toggleRowSelected(index);
      }
  
    };
  
    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      updateMyData(index, id, value);
    };
  
    // If the initialValue is changed externall, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
  
    if (!editable) {
      return `${initialValue}`;
    }
    return <input value={value} onChange={onChange} onBlur={onBlur} style={{fontWeight: 'bold'}} />;
  };

  export const UneditableCell = ({
    value: initialValue,
    row: { index, isSelected },
    column: { id },
  }) => {
    return <input value={initialValue} style={{fontWeight: 'bold', backgroundColor: '#f2f2f2'}}  readOnly={true}/>;
  };
  
  export const EditableDropDownCell = ({
    value: initialValue,
    row: { index, isSelected },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
    editable,
    toggleRowSelected
  }) => {
    // We need to keep and update the state of the cell normally
  
    if(id == 'active'){
      initialValue = initialValue || false;
    } else{
      initialValue = initialValue || '';
    }
  
    const [value, setValue] = React.useState(initialValue);
  
    const onChange = (e) => {
      setValue(e.target.value);
  
      if(!isSelected){
        toggleRowSelected(index);
      }
  
    };
  
    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      updateMyData(index, id, value);
    };
  
    // If the initialValue is changed externall, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
  
    if (!editable) {
      return `${initialValue}`;
    }
  
   return <select onChange={onChange} onBlur={onBlur} value={value} name="thing" id="thing2"  style={{fontWeight: 'bold'}}>
      <option value="Incomplete">Incomplete</option>
      <option value="Completed">Completed</option>
      <option value="Not Needed">Not Needed</option>
    </select>
  
    return <input value={value} onChange={onChange} onBlur={onBlur} />;
  };
  
  export const EditableDropDownJobCell = ({
    value: initialValue,
    row: { index, isSelected, values },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
    editable,
    toggleRowSelected
  }) => {
    // We need to keep and update the state of the cell normally
  
  
    if(id == 'active'){
      initialValue = initialValue || false;
    } else{
      initialValue = initialValue || '';
    }
  
    const [value, setValue] = React.useState(initialValue);
  
    const onChange = (e) => {
      setValue(e.target.value);
  
      if(!isSelected){
        toggleRowSelected(index);
      }
  
    };
  
    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      updateMyData(index, id, value);
    };
  
    // If the initialValue is changed externall, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
  
    if (!editable) {
      return `${initialValue}`;
    }
  
   return <select onChange={onChange} onBlur={onBlur} value={value} name="thing" id="thing2"  style={{fontWeight: 'bold'}}>
      <option value="Completed">Completed</option>
      <option value="Inactive">Inactive</option>
      <option value="Active">Active</option>
    </select>
  
    return <input value={value} onChange={onChange} onBlur={onBlur} />;
  };

export const EditableCheckboxCell = ({
  value: initialValue,
  row: { index, isSelected },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editable,
  toggleRowSelected
}) => {
  // We need to keep and update the state of the cell normally
  initialValue = initialValue || false;

  const [checked, setChecked] = React.useState(initialValue);

  const onChange = () => {
    const newValue = !checked;
    setChecked(newValue);

    if (!isSelected) {
      toggleRowSelected(index);
    }

    // Update the external data immediately on checkbox change
    updateMyData(index, id, newValue);
  };

  // If the initialValue is changed externally, sync it up with our state
  React.useEffect(() => {
    setChecked(initialValue);
  }, [initialValue]);

  if (!editable) {
    return <input type="checkbox" checked={checked} disabled />;
  }

  return (
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      // onBlur={onChange}
    />
  );
};


export const EditableDateCell = ({
  value: initialValue,
  row: row,
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editable,
  toggleRowSelected
}) => {
  // We need to keep and update the state of the cell normally
  initialValue = initialValue || '';

  const [dateValue, setDateValue] = React.useState(initialValue);

  const onChange = (e) => {
    const newValue = e.target.value;
    setDateValue(newValue);
    if (!row.isSelected) {
      toggleRowSelected(row.index);
    }

    // Update the external data immediately on date input change
    updateMyData(row.index, id, newValue);
  };

  // If the initialValue is changed externally, sync it up with our state
  React.useEffect(() => {
    setDateValue(initialValue);
  }, [initialValue]);

  let cleanerPaid = row.values.cleaner_paid;

  if (!editable || cleanerPaid) {
    return <input type="date" value={dateValue} readOnly />;
  }

  return <input type="date" value={dateValue} onChange={onChange} onBlur={onChange} />;
};

export const DropDownStopNumberCell = ({
  value: initialValue,
  row: { index, isSelected },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editable,
  toggleRowSelected
}) => {
  // We need to keep and update the state of the cell normally
  initialValue = initialValue || ''; // Ensure initialValue is a string

  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);

    if (!isSelected) {
      toggleRowSelected(index);
    }
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed externally, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!editable) {
    return `${initialValue}`;
  }

  // Define the allowed options
  let stopNumberOptions = [...Array(20).keys()].map((number) => (
    number+1
  ));

  stopNumberOptions.push('');

  return (
    <select onChange={onChange} onBlur={onBlur} value={value} name="thing" id="thing2" style={{ fontWeight: 'bold', border: 'none' }}>
      {stopNumberOptions.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export const DropDownCleanNumberCell = ({
  value: initialValue,
  row: { index, isSelected },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editable,
  toggleRowSelected
}) => {
  // We need to keep and update the state of the cell normally
  initialValue = initialValue || ''; // Ensure initialValue is a string

  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);

    if (!isSelected) {
      toggleRowSelected(index);
    }
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed externally, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!editable) {
    return `${initialValue}`;
  }

  // Define the allowed options
  const cleanNumberOptions = [
    '',
    'First Clean',
    'Second Clean',
    'Third Clean',
    'Fourth Clean',
    'Fifth Clean',
    'Reclean',
    'Lot Trash Pickup',
    'Rough Clean',
    'Final Clean',
    'Reclean One',
    'Reclean Two',
    'Service Charge',
    'Trip Charge',
    'SPEC',
    'House Sweep',
    'Warranty',
    'Moveout',
    'Stairwells',
    'Residential',
    'Inspections',
    'Adjustment'
  ];

  const powerWashOptions = [
    'Power Wash',
    'Power Wash 2',
    'Power Wash 3',
    'Power Washing Touchup',
  ];

  let keyCount = 1;

  return (
    <select onChange={onChange} onBlur={onBlur} value={value} name="thing" id="thing2" style={{ fontWeight: 'bold', border: 'none' }}>
      {cleanNumberOptions.map((option) => {
       let dropdowndownOptions = <option key={`${option}-${keyCount}`} value={option}>
          {option}
        </option>
        keyCount++;

        return dropdowndownOptions
    })}
    </select>
  );
};

export const DropDownPowerWashCell = ({
  value: initialValue,
  row: { index, isSelected },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editable,
  toggleRowSelected
}) => {
  // We need to keep and update the state of the cell normally
  initialValue = initialValue || ''; // Ensure initialValue is a string

  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);

    if (!isSelected) {
      toggleRowSelected(index);
    }
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed externally, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!editable) {
    return `${initialValue}`;
  }

  // Define the allowed options
  const powerWashOptions = [
    'Power Wash',
    'Power Wash 2',
    'Power Wash 3',
    'Power Washing Touchup',
  ];

  let keyCount = 1;

  return (
    <select onChange={onChange} onBlur={onBlur} value={value} name="thing" id="thing2" style={{ fontWeight: 'bold', border: 'none' }}>
      {powerWashOptions.map((option) => {
       let dropdowndownOptions = <option key={`${option}-${keyCount}`} value={option}>
          {option}
        </option>
        keyCount++;

        return dropdowndownOptions
    })}
    </select>
  );
};

export const DropDownInvoiceJobType = ({
  value: initialValue,
  row: { index, isSelected },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editable,
  toggleRowSelected
}) => {
  // We need to keep and update the state of the cell normally
  initialValue = initialValue || ''; // Ensure initialValue is a string

  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);

    if (!isSelected) {
      toggleRowSelected(index);
    }
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed externally, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!editable) {
    return `${initialValue}`;
  }

  // Define the allowed options
  const invoiceJobTypeOptions = [
    'Landscaping',
    'Trash'
  ];

  return (
    <select onChange={onChange} onBlur={onBlur} value={value} name="thing" id="thing2" style={{ fontWeight: 'bold',  border: 'none'  }}>
      {invoiceJobTypeOptions.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export const DropDownCommunityCell = ({
  value: initialValue,
  row: { index, isSelected },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editable,
  toggleRowSelected,
  communities,
}) => {
  // We need to keep and update the state of the cell normally
  initialValue = initialValue || ''; // Ensure initialValue is a string

  const [value, setValue] = React.useState(initialValue);

  let keyCount = 1;

  const onChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);

    if (!isSelected) {
      toggleRowSelected(index);
    }
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed externally, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!editable) {
    return `${initialValue}`;
  }


  let communityOptions = [
    null,
    ...communities.map((community) => community.name),
  ];

  communityOptions.push(initialValue);

  return (
    <select onChange={onChange} onBlur={onBlur} value={value} name="community" id="community" style={{ fontWeight: 'bold', border: 'none' }}>
      {communityOptions.map((option) => {
        let dropDownDownOption =
          <option key={`${option}-${keyCount}`} value={option}>
          {option}
        </option>

        keyCount++;
      
       return dropDownDownOption
      })}
    </select>
  );
};

export const DropDownCrewCell = ({
  value: initialValue,
  row: { index, isSelected },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editable,
  toggleRowSelected,
  crews,
}) => {
  // We need to keep and update the state of the cell normally
  initialValue = initialValue || ''; // Ensure initialValue is a string

  crews = crews || [];

  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);

    if (!isSelected) {
      toggleRowSelected(index);
    }
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed externally, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!editable) {
    return `${initialValue}`;
  }

  let crewOptions = [
    null,
    ...crews.map((crew) => crew.name),
  ];

  crewOptions.push(initialValue);

  let keyCount = 1;

  return (
    <select onChange={onChange} onBlur={onBlur} value={value} name="crew" id="crew" style={{ fontWeight: 'bold', border: 'none' }}>
      {crewOptions.map((option) => {
        let dropDownOptions =
        <option key={`${option}-${keyCount}`} value={option}>
          {option}
        </option>
        keyCount++;

        return dropDownOptions;
      })}
    </select>
  );
};

export const DropDownBuilderCell = ({
  value: initialValue,
  row: { index, isSelected },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editable,
  toggleRowSelected,
  builders,
}) => {
  // We need to keep and update the state of the cell normally
  initialValue = initialValue || 'No Builder Saved'; // Ensure initialValue is a string

  const [value, setValue] = React.useState(initialValue);

  let keyCount = 1;

  const onChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);

    if (!isSelected) {
      toggleRowSelected(index);
    }
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed externally, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!editable) {
    return `${initialValue}`;
  }


  let builderOptions = [
    null,
    ...builders.map((builder) => builder.name),
  ];

  builderOptions.push(initialValue);

  return (
    <select onChange={onChange} onBlur={onBlur} value={value} name="builder" id="builder" style={{ fontWeight: 'bold', border: 'none' }}>
      {builderOptions.map((option) => {
        let dropDownDownOption =
          <option key={`${option}-${keyCount}`} value={option}>
          {option}
        </option>

        keyCount++;
      
       return dropDownDownOption
      })}
    </select>
  );
};


export const EditableNotesCell = ({
  value: initialValue,
  row,
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editable,
  toggleRowSelected,
  setChangedRows,
  changedRows,
}) => {
  // We need to keep and update the state of the cell normally
  initialValue = initialValue || '';

  const [value, setValue] = React.useState(initialValue);
  const [isFocused, setIsFocused] = React.useState(false);

  const onChange = (e) => {
    setValue(e.target.value);

    if (!row.isSelected) {
      toggleRowSelected(row.index);
      changedRows.add(row.index);
      setChangedRows(changedRows);
    }
  };

  const onBlur = () => {
    updateMyData(row.index, id, value);
    setIsFocused(false);
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  // If the initialValue is changed externally, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!editable) {
    return `${initialValue}`;
  }

  return (
    <textarea
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      style={{
        fontWeight: 'bold',
        width: '100%',
        height: isFocused ? 'auto' : '1.5em',
        overflow: 'hidden',
        resize: isFocused ? 'both' : 'none',
        border: 'none',
      }}
      rows={isFocused ? '5' : '1'}
    />
  );
};





