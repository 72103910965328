import React from "react";
import styled from "styled-components";
import { Button, Spinner } from 'react-bootstrap'
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect
} from "react-table";
import { matchSorter } from "match-sorter";

import {
    SelectColumnFilter
} from '../TableFilters';

import {
    EditableDropDownJobCell,
    EditableDropDownCell,
    EditableCellTime,
    EditableCheckboxCell,
    EditableDateCell,
    DropDownCleanNumberCell,
    DropDownCommunityCell,
    DropDownCrewCell,
    DropDownStopNumberCell,
    EditableNotesCell

} from '../TableCells';


const cleaningColumns = [
      {
        Header: "Customer Information",
        columns: [
          {
            Header: "Builder Name",
            accessor: "name",
            Filter: SelectColumnFilter,
            filter: "includes",
            isVisible: false,
            toggleHidden: true
          },
          {
            Header: "Active",
            accessor: "active",
            Filter: SelectColumnFilter,
            filter: "includes",
            Cell: EditableCheckboxCell,
          },
          {
            Header: "Notes",
            accessor: "notes",
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`,
            Cell: EditableNotesCell,
          },
        ]
      },
      {
        Header: "Submission Info",
        columns: [
              {
                Header: "Submitted By",
                accessor: "submitted_by",
                aggregate: "count",
                Aggregated: ({ value }) => `${value} Names`,
                isVisible: false,
                toggleHidden: true
              },
              {
                Header: "Submission Time",
                accessor: "date_submitted",
                aggregate: "count",
                Aggregated: ({ value }) => `${value} Names`,
                Cell: EditableCellTime,
              },
              {
                Header: "Last Modified",
                accessor: "last_modified",
                aggregate: "count",
                Aggregated: ({ value }) => `${value} Names`,
                Cell: EditableCellTime,
              },
              {
                Header: "Last Modified By",
                accessor: "last_modified_by",
                aggregate: "count",
                Aggregated: ({ value }) => `${value} Names`,
                isVisible: false,
                toggleHidden: true
              },
        ]
      }
    ]

export default cleaningColumns;
