const submitCleaningBuilder = async (formData, resetForm, resetState, userName, accessToken) => {

    let addCleaningData ={};
    addCleaningData["cleaningBuilders"] = formData;

    console.log(formData);

    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT
      const response = await fetch(`${REACT_APP_SERVER_URL}/CleaningBuilders/addCleaningBuilder`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
            'data-request': 'read'
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            userName: userName,
            cleaningBuilderData: addCleaningData
        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }
      else if(response.status === 200){
        alert(`Builder ${formData.builderName} Added`);
        resetForm();
        resetState();
      }
      else if(response.status <= 402){
        alert(`Error adding Builders ${formData.name}, error code ${response.status}`)
      }

    } catch (error) {
      // handle error as needed
      alert(error);
    } 
  }

  const getActiveCleaningBuilders  = async (accessToken) => {
      try {
          const { REACT_APP_SERVER_URL } = process.env;
          const response = await fetch(`${REACT_APP_SERVER_URL}/CleaningBuilders`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + accessToken,
                'data-request': 'read'
            },
          })

          let communitiesData = await response.json();

          let filteredData = communitiesData.filter(
            (community) => {
              return community.active === true;
            }
          );
          return filteredData;

        } catch (err) {
          console.log(err);
          alert(err);
        }
    }

  export  {submitCleaningBuilder, getActiveCleaningBuilders};