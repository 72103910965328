import React from "react";
import styled from "styled-components";
import { Button, Spinner } from 'react-bootstrap'
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect
} from "react-table";
import { matchSorter } from "match-sorter";

import {
    SelectColumnFilter
} from '../TableFilters';

import {
    EditableDropDownJobCell,
    EditableDropDownCell,
    EditableCellTime,
    EditableCheckboxCell,
    DropDownBuilderCell,
    EditableNotesCell

} from '../TableCells';

const createPowerWashColumns = (builders)=>{
  const cleaningColumns = [
        {
          Header: "Customer Information",
          columns: [
            {
              Header: "Community Name",
              accessor: "name",
              Filter: SelectColumnFilter,
              filter: "includes",
              isVisible: false,
              toggleHidden: true
            },
            {
              Header: "Builder",
              accessor: "builder",
              Filter: SelectColumnFilter,
              filter: "includes",
              Cell: (props) => <DropDownBuilderCell {...props} builders={builders} />,
              enableMultiSort: true
            },
            {
              Header: "Active",
              accessor: "active",
              Filter: SelectColumnFilter,
              filter: "includes",
              Cell: EditableCheckboxCell,
            },
            {
              Header: "Notes",
              accessor: "notes",
              aggregate: "count",
              Aggregated: ({ value }) => `${value} Names`,
              Cell: EditableNotesCell,
            },
          ]
        },
        {
          Header: "Submission Info",
          columns: [
                {
                  Header: "Submitted By",
                  accessor: "submitted_by",
                  aggregate: "count",
                  Aggregated: ({ value }) => `${value} Names`,
                  isVisible: false,
                  toggleHidden: true
                },
                {
                  Header: "Submission Time",
                  accessor: "date_submitted",
                  aggregate: "count",
                  Aggregated: ({ value }) => `${value} Names`,
                  Cell: EditableCellTime,
                },
                {
                  Header: "Last Modified",
                  accessor: "date_last_edited",
                  aggregate: "count",
                  Aggregated: ({ value }) => `${value} Names`,
                  Cell: EditableCellTime,
                },
                {
                  Header: "Last Modified By",
                  accessor: "last_edited_by",
                  aggregate: "count",
                  Aggregated: ({ value }) => `${value} Names`,
                  isVisible: false,
                  toggleHidden: true
                },
          ]
        }
      ]
      return cleaningColumns;
    }

export default createPowerWashColumns;
